import dayjs from "dayjs";
import { Suspense, useCallback, useState, lazy, useMemo } from "react";

import Loading from "components/Loading";
import useCreateOrder from "hooks/api/useCreateOrder";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import { hasAddressAndLocation } from "pages/OrderCreate/utils/queryParameter.utils";
import { isBrowser, isTablet } from "utils/deviceDetect";

const OrderCreate = lazy(() =>
  isBrowser
    ? import("pages/OrderCreate/pc/OrderCreate")
    : isTablet
    ? import("pages/OrderCreate/tb/OrderCreate")
    : import("pages/OrderCreate/sp/OrderCreate")
);

export const REMARKS_ERROR_MESSAGE = "※255文字以内で入力してください";

// 本体
const OrderCreateContainer = () => {
  const today = dayjs().startOf("day");

  // クエリパラメータからの入力の初期値として設定
  const {
    constructionName,
    address,
    location,
    numberOfCars,
    automobileType,
    startDate,
    endDate,
    remarks,
    kizukuSpotId,
  } = useMemo(() => {
    const decodeQuery = decodeURI(window.location.search);
    const urlParams = new URLSearchParams(decodeQuery);
    return {
      constructionName: urlParams.get("constructionName") ?? "",
      address: hasAddressAndLocation() ? urlParams.get("address") ?? "" : "",
      location: {
        latitude: hasAddressAndLocation() ? Number(urlParams.get("latitude")) : 35.681236,
        longitude: hasAddressAndLocation() ? Number(urlParams.get("longitude")) : 139.767125,
      },
      numberOfCars: Number(urlParams.get("numberOfCars")) > 0 ? Number(urlParams.get("numberOfCars")) : 1,
      automobileType: urlParams.get("automobileType") ?? "",
      startDate: urlParams.get("startDate") ?? today.format("YYYY-MM-DD"),
      endDate: urlParams.get("endDate") ?? today.format("YYYY-MM-DD"),
      remarks: urlParams.get("remarks") ?? "",
      kizukuSpotId: urlParams.get("constructionCode") ?? undefined,
    };
  }, [today]);

  const [conditions, setConditions] = useState<CreateOrder>({
    constructionName,
    address,
    location,
    numberOfCars,
    automobileType,
    startDate,
    endDate,
    remarks,
    images: [],
    kizukuSpotId,
  });

  const [step, setStep] = useState(1);

  const setStepAndResetScrollPosition = (step: number) => {
    window.scrollTo(0, 0);
    setStep(step);
  };

  const { createOrder } = useCreateOrder();
  const { showErrorModal } = useErrorModalStatusContext();

  const submit = useCallback(async () => {
    const status = await createOrder(conditions).catch(() => {
      showErrorModal({ httpMethod: "post" });
    });

    // 成功したら完了画面へ
    if (status === 201) setStep(4);
  }, [conditions, createOrder, showErrorModal]);

  return (
    <Suspense fallback={<Loading />}>
      <OrderCreate
        step={step}
        setStep={setStepAndResetScrollPosition}
        conditions={conditions}
        setConditions={setConditions}
        submit={submit}
      />
    </Suspense>
  );
};

export default OrderCreateContainer;
