import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";

import Loading from "components/Loading";
import useFindContractsByOpportunityId from "hooks/api/useFindContractsByOpportunityId";
import useUploadContract from "hooks/api/useUploadContract";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import { useInfoModal } from "hooks/useModal";
import { isBrowser, isTablet } from "utils/deviceDetect";

const ContractList = lazy(() =>
  isBrowser
    ? import("pages/Contract/pc/ContractList")
    : isTablet
    ? import("pages/Contract/tb/ContractList")
    : import("pages/Contract/sp/ContractList")
);

const Contract = () => {
  const params = useParams();
  const navigate = useNavigate();
  const { contracts, error: contractsError, mutate } = useFindContractsByOpportunityId(params.id ?? "");

  const { uploadContract } = useUploadContract();

  const { InfoModal, showInfoModal, hideInfoModal } = useInfoModal();
  const [infoModalTitle, setInfoModalTitle] = useState<string>("");

  const { showErrorModal } = useErrorModalStatusContext();

  const upload = useCallback(
    async (image: Attachment, contractId: string) => {
      const status = await uploadContract({ attachment: image }, contractId).catch((e) => {
        showErrorModal({ httpMethod: "post" });
        return e.response.status;
      });
      if (status === 201) {
        setInfoModalTitle("契約書を提出しました");
        showInfoModal();
        mutate(contracts);
        return status;
      }
    },
    [uploadContract, showInfoModal, mutate, contracts, showErrorModal]
  );

  useEffect(() => {
    if (contractsError) {
      showErrorModal({ httpMethod: "get", onPostClose: () => navigate(-1) });
    }
  }, [contractsError, navigate, showErrorModal]);

  return (
    <>
      <Suspense fallback={<Loading />}>
        <ContractList contracts={contracts} uploadHandler={upload} onBreadcrumbClick={() => navigate("/order")} />
      </Suspense>
      <InfoModal title={infoModalTitle} onClick={hideInfoModal} />
    </>
  );
};

export default Contract;
