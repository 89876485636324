import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import Button from "components/Button";
import Modal from "components/Modal";
import color from "constants/color";
import font from "constants/font";

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;
const Warning = styled.div`
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;
const ExclamationIcon = styled(FontAwesomeIcon)`
  height: 23px;
  width: 23px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.warning};
`;
const Title = styled.span`
  font-size: ${font.size20};
`;
const ActionArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin-bottom: 20px;
`;

/**
 *
 * やり直しが効かない操作を警告するモーダル
 */
const WarningModal = ({
  isVisible,
  onClose,
  title,
  label,
  onClick,
}: {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  label: string;
  onClick: () => Promise<void> | void;
}) => {
  return (
    <Modal isVisible={isVisible} onClose={onClose}>
      <>
        <Body>
          <Warning>
            <ExclamationIcon icon={faCircleExclamation} />
            <Title>{title}</Title>
          </Warning>
        </Body>
        <ActionArea>
          <Button label="キャンセル" onClick={onClose} type="secondary" width="160px" />
          <Button label={label} onClick={onClick} type="primary" width="160px" />
        </ActionArea>
      </>
    </Modal>
  );
};

export default WarningModal;
