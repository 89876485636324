import { lazy, Suspense, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";
import useFindContracts from "hooks/api/useFindContracts";
import useGetUsers from "hooks/api/useGetUsers";
import { useDebounce } from "hooks/useDebounce";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import {
  UsageSortColumn,
  UsageSort,
  initialUsageSort,
  update,
  findUsageSortColumnAndDirection,
  convertUsageSortColumnToName,
} from "pages/Usage/usageSortColumn";
import { UsageStatusFilter } from "pages/Usage/usageStatus";
import { findUniqueDepartments } from "utils/accountUtils";
import { isBrowser, isTablet } from "utils/deviceDetect";
import { findFromLocalStorage, saveToLocalStorage } from "utils/localStorageUtils";

const IS_KEEP_USAGE_FILTER_KEY = "keep-usage-status";

const PAGE_SIZE = 20;

const UsageList = lazy(() =>
  isBrowser
    ? import("pages/Usage/pc/UsageList")
    : isTablet
    ? import("pages/Usage/tb/UsageList")
    : import("pages/Usage/sp/UsageList")
);

const UsageListContainer = () => {
  const queryParameter = new URLSearchParams(window.location.search);

  const [searchKeyword, setSearchKeyword] = useState<string | undefined>();
  const { debouncedValue } = useDebounce(searchKeyword ?? "", 500);

  const [usageStatusFilter, setUsageStatusFilter] = useState<UsageStatusFilter | undefined>(
    (queryParameter.get("filter") as UsageStatusFilter) ?? UsageStatusFilter.all
  );

  const [currentPageNumber, setCurrentPageNumber] = useState<number>(1);

  const [chosenAccount, setChosenAccount] = useState<Account | undefined>();
  const [chosenDepartment, setChosenDepartment] = useState<string | undefined>();
  const { accountList } = useGetUsers();
  const departmentList = findUniqueDepartments(accountList);

  const [usageSort, setUsageSort] = useState<UsageSort>(initialUsageSort);

  const { sendEvent } = useGoogleAnalytics(true);
  const updateUsageSort = (column: UsageSortColumn, previous: UsageSort): void => {
    setUsageSort(update(column, previous));
    sendEvent({
      action: `利用状況_${convertUsageSortColumnToName(column)}__ソート`,
      category: "sort",
      label: "/usage",
    });
  };

  const { sortColumn, sortDirection } = findUsageSortColumnAndDirection(usageSort);

  const { contractsResponse, error: contractsError } = useFindContracts({
    pageSize: PAGE_SIZE,
    pageNumber: currentPageNumber,
    usageStatusFilter: usageStatusFilter ?? findFromLocalStorage(IS_KEEP_USAGE_FILTER_KEY, UsageStatusFilter.all),
    sortDirection: sortDirection,
    sortColumn: sortColumn,
    contactId: chosenAccount?.id !== "ALL" ? chosenAccount?.id : undefined,
    department: chosenDepartment !== "すべて" ? chosenDepartment : undefined,
    searchKeyword: debouncedValue,
  });

  const navigate = useNavigate();
  const onClickContractRow = (contractId: string): void => {
    sendEvent({
      action: "利用一覧から利用詳細遷移_エリア",
      category: "other",
      label: "/usage",
    });
    navigate(`/usage/${contractId}`);
  };

  const { showErrorModal } = useErrorModalStatusContext();
  useEffect(() => {
    if (contractsError) {
      showErrorModal({ httpMethod: "get" });
    }
  }, [contractsError, showErrorModal]);

  return (
    <Suspense fallback={<Loading />}>
      <UsageList
        isFirstFetch={
          searchKeyword == null &&
          chosenAccount == null &&
          chosenDepartment == null &&
          usageStatusFilter == null &&
          // usageページでリロード時に必要。一度でもfetch成功していなければ、all以外にできない。allのままならfirstFetchの可能性有とみなす
          findFromLocalStorage(IS_KEEP_USAGE_FILTER_KEY, UsageStatusFilter.all) === UsageStatusFilter.all
        }
        contractList={contractsResponse?.data}
        searchKeyword={searchKeyword ?? ""}
        setSearchKeyword={setSearchKeyword}
        usageStatusFilter={usageStatusFilter ?? findFromLocalStorage(IS_KEEP_USAGE_FILTER_KEY, UsageStatusFilter.all)}
        setUsageStatus={setUsageStatusFilter}
        setUsageStatusLocalStorage={(status: UsageStatusFilter) => saveToLocalStorage(IS_KEEP_USAGE_FILTER_KEY, status)}
        accountList={accountList ?? []}
        chosenAccount={chosenAccount ?? { id: "ALL", name: "すべて", department: "すべて" }}
        setChosenAccount={setChosenAccount}
        departmentList={departmentList ?? []}
        chosenDepartment={chosenDepartment ?? "すべて"}
        setChosenDepartment={setChosenDepartment}
        usageSort={usageSort}
        updateUsageSort={updateUsageSort}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        totalPageNumber={contractsResponse ? contractsResponse.pagination.totalPages : 1}
        onClickContractRow={onClickContractRow}
      />
    </Suspense>
  );
};

export default UsageListContainer;
