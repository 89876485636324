import { useNavigate } from "react-router-dom";
import styled from "styled-components";

import Button from "components/Button";
import Modal from "components/Modal";
import color from "constants/color";
import font from "constants/font";
import { isBrowser, isTablet } from "utils/deviceDetect";

const ModalInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
  width: 100%;
  box-sizing: border-box;
  padding 32px 40px 20px 40px;
`;
const ButtonWrap = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
`;
const ContentBox = styled.div`
  padding 0;
`;
const Title = styled.div`
  text-align: center;
  font-size: ${font.size20};
  margin-bottom: 16px;
`;
const SelectBox = styled.div`
  display: flex;
  justify-content: space-between;
  flex-direction: ${isBrowser || isTablet ? "row" : "column"};
  margin-bottom: ${isBrowser || isTablet ? null : "40px"};
`;
const OrderMethodBox = styled.div`
  height: 114px;
  width: 252px;
  background-color: ${color.lightBlue};
  font-size: ${font.size16};
  font-weight: 700;
  color: ${color.text.blue};
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
  &:hover {
    cursor: pointer;
    outline: 1px solid ${color.blue};
  }
  &:first-child {
    margin-bottom: ${isBrowser || isTablet ? null : "16px"};
  }
`;

const SelectOrderMethodModal = ({ isVisible, onClose }: { isVisible: boolean; onClose: () => void }) => {
  const navigate = useNavigate();

  const navigateOrderForm = () => {
    navigate("/order/create");
    onClose();
  };

  const navigateOrderFileUpload = () => {
    navigate("/order/create-by-file");
    onClose();
  };

  return (
    <Modal
      isVisible={isVisible}
      onClose={onClose}
      width={isBrowser || isTablet ? "600px" : "100%"}
      height={isBrowser || isTablet ? "300px" : "auto"}
      paddingHorizontal={!isBrowser && !isTablet ? 16 : 0}
    >
      <ModalInner>
        <ContentBox>
          <Title>駐車場検索の依頼方法を選択してください</Title>
          <SelectBox>
            <OrderMethodBox onClick={() => navigateOrderForm()}>
              <div style={{ textAlign: "center" }}>
                <img src="/images/file-pen.svg" alt="フォーム入力" height="32px" />
                <div>フォームに入力</div>
              </div>
            </OrderMethodBox>
            <OrderMethodBox onClick={() => navigateOrderFileUpload()}>
              <div style={{ textAlign: "center" }}>
                <img src="/images/upload-file.svg" alt="フォーム入力" height="32px" />
                <div>ファイルをアップロード</div>
              </div>
            </OrderMethodBox>
          </SelectBox>
        </ContentBox>
        <ButtonWrap>
          <Button type="secondary" label="閉じる" onClick={onClose} />
        </ButtonWrap>
      </ModalInner>
    </Modal>
  );
};

export default SelectOrderMethodModal;
