import { faWindowRestore } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import color from "constants/color";
import font from "constants/font";
import { isBrowser, isTablet } from "utils/deviceDetect";

// パーツ定義
const Wrap = styled.footer`
  padding: 0 30px;
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-shrink: 0;
  background-color: ${color.footer.background};
  ${!isBrowser &&
  !isTablet &&
  `
    flex-direction: column;
    padding: 16px 30px;
  `}
`;
const LinkWrap = styled.div`
  margin: 0;
  padding: 0;
  display: flex;
  align-items: center;
  gap: 20px;
  list-style: none;
`;
const Link = styled.a`
  display: flex;
  align-items: center;
  gap: 3px;
  color: ${color.text.gray};
  font-size: ${font.size12};
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
`;
const CopyRight = styled.small`
  color: ${color.text.gray};
  font-size: ${font.size12};
`;

// 本体
const Footer = () => {
  return (
    <Wrap>
      <LinkWrap>
        <Link href="https://landit.co.jp/terms" target="_blank">
          利用規約
          <FontAwesomeIcon icon={faWindowRestore} size="sm" />
        </Link>
        <Link href="https://landit.co.jp/privacypolicy" target="_blank">
          プライバシーポリシー
          <FontAwesomeIcon icon={faWindowRestore} size="sm" />
        </Link>
      </LinkWrap>
      <CopyRight>© 2021 Landit Inc.</CopyRight>
    </Wrap>
  );
};

export default Footer;
