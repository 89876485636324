import { lazy, Suspense, useCallback, useEffect, useState } from "react";

import Loading from "components/Loading";
import useCountMonthlyBillings from "hooks/api/useCountMonthlyBillings";
import useFindBillingAttachment from "hooks/api/useFindBillingAttachment";
import useFindBillings from "hooks/api/useFindBillings";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import useUserContext from "hooks/useUserContext";
import {
  BillingSort,
  BillingSortColumn,
  findBillingSortColumnAndDirection,
  initialBillingSort,
  update,
} from "pages/Billing/pc/billingSortColumn";
import useBillingCommonContainer from "pages/Billing/useBillingCommonContainer";
import { isBrowser, isTablet } from "utils/deviceDetect";
import { downloadPdf } from "utils/fileUtils";

const BillingListPresenter = lazy(() =>
  isBrowser
    ? import("pages/Billing/pc/BillingListPresenter")
    : isTablet
    ? import("pages/Billing/tb/BillingListPresenter")
    : import("pages/Billing/sp/BillingListPresenter")
);

const PAGE_SIZE = 20;

const BillingListContainer = () => {
  const {
    yearsOfUse,
    searchKeyword,
    setSearchKeyword,
    currentPageNumber,
    setCurrentPageNumber,
    chosenAccount,
    setChosenAccount,
    chosenDepartment,
    setChosenDepartment,
    accountList,
    departments,
    billingYear,
    setBillingYear,
    billingMonth,
    setBillingMonth,
    toUsageDetail,
  } = useBillingCommonContainer();

  const { showErrorModal } = useErrorModalStatusContext();

  // ソート
  const [billingSort, setBillingSort] = useState<BillingSort>(initialBillingSort);
  const updateBillingSort = (column: BillingSortColumn, previous: BillingSort): void => {
    setBillingSort(update(column, previous));
  };
  const onClickSort = (column: BillingSortColumn, previous: BillingSort) => updateBillingSort(column, previous);
  const { sortColumn, sortDirection } = findBillingSortColumnAndDirection(billingSort);

  const { monthly } = useCountMonthlyBillings(billingYear);

  const {
    billingList,
    monthTotalRent,
    pagination,
    error: billingsError,
    mutate,
  } = useFindBillings({
    pageSize: PAGE_SIZE,
    pageNumber: currentPageNumber,
    sortDirection: sortDirection,
    sortColumn: sortColumn,
    billingYear: billingYear,
    billingMonth: billingMonth.month,
    contactId: chosenAccount.id !== "ALL" && chosenAccount.id !== "" ? chosenAccount.id : undefined,
    department: chosenDepartment !== "すべて" && chosenDepartment !== "" ? chosenDepartment : undefined,
    searchKeyword: searchKeyword,
  });

  const setBillingMonthAfterFetch = useCallback(() => {
    if (monthly.length > 1) {
      setBillingMonth((prev) => monthly.find((m) => m.month === prev.month) || monthly[0]);
    }
  }, [monthly, setBillingMonth]);

  useEffect(() => {
    setBillingMonthAfterFetch();
  }, [billingsError, setBillingMonthAfterFetch]);

  useEffect(() => {
    if (billingsError) showErrorModal({ httpMethod: "get" });
  }, [billingsError, showErrorModal]);

  const { findBillingAttachment } = useFindBillingAttachment();

  const { sendEvent } = useGoogleAnalytics(true);

  const { user } = useUserContext();

  const onClickBillingDownloadLink = useCallback(
    async (billingId: string, constructionName: string) => {
      const attachment = await findBillingAttachment(billingId).catch(() => {
        showErrorModal({ httpMethod: "get" });
      });
      attachment
        ? downloadPdf(attachment, `${user.companyName}御中_${constructionName}_ご請求書`)
        : showErrorModal({ httpMethod: "get" });
      mutate();
      sendEvent({
        category: "契約ごとの請求書をダウンロード",
        action: "link",
        label: "/billing",
      });
    },
    [sendEvent, findBillingAttachment, mutate, showErrorModal, user]
  );

  return (
    <Suspense fallback={<Loading />}>
      <BillingListPresenter
        billingList={billingList}
        monthTotalRent={monthTotalRent}
        searchKeyword={searchKeyword}
        setSearchKeyword={setSearchKeyword}
        chosenAccount={chosenAccount}
        setChosenAccount={setChosenAccount}
        chosenDepartment={chosenDepartment}
        setChosenDepartment={setChosenDepartment}
        yearsOfUse={yearsOfUse}
        billingYear={billingYear}
        setBillingYear={setBillingYear}
        monthly={monthly}
        billingMonth={billingMonth}
        setBillingMonth={setBillingMonth}
        billingSort={billingSort}
        accounts={accountList}
        departments={departments}
        onClickSort={onClickSort}
        currentPageNumber={currentPageNumber}
        setCurrentPageNumber={setCurrentPageNumber}
        totalPageNumber={pagination.totalPages}
        onClickRow={toUsageDetail}
        onClickBillingDownloadLink={onClickBillingDownloadLink}
      />
    </Suspense>
  );
};

export default BillingListContainer;
