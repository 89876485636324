import { useCallback } from "react";

import useClient from "hooks/useClient";
import useFirebaseStorage from "hooks/useFirebaseStorage";
import useUserContext from "hooks/useUserContext";

const useCreateOrder = (): {
  createOrder: (data: CreateOrder) => Promise<number>;
  orderByFile: (data: { filename: string; fileKey: string }) => Promise<number>;
} => {
  const { client } = useClient();
  const { uploadOrderAttachment } = useFirebaseStorage();
  const { user } = useUserContext();
  const createOrder = useCallback(
    async (data: CreateOrder) => {
      const urls = await Promise.all(data.images.map((image) => uploadOrderAttachment(user.contactId, image)));
      const { status } = await client({
        method: "post",
        url: "/order",
        data: {
          constructionName: data.constructionName,
          address: data.address,
          latitude: data.location.latitude,
          longitude: data.location.longitude,
          numberOfCars: data.numberOfCars,
          automobileType: data.automobileType,
          startDate: data.startDate,
          endDate: data.endDate,
          remarks: data.remarks,
          attachmentUrls: urls,
          kizukuSpotId: data.kizukuSpotId,
        },
      });
      return status;
    },
    [client, uploadOrderAttachment, user.contactId]
  );

  const orderByFile = useCallback(
    async (data: { filename: string; fileKey: string }) => {
      const { status } = await client({
        method: "post",
        url: "/order/file",
        data: data,
      });
      return status;
    },
    [client]
  );

  return { createOrder, orderByFile };
};

export default useCreateOrder;
