import { ChangeEvent, Dispatch, lazy, SetStateAction, Suspense, useState } from "react";

import Loading from "components/Loading";
import { REQUEST_SERVICES, RequestService } from "constants/option";
import useCreateOption from "hooks/api/useCreateOption";
import useFindOrders from "hooks/api/useFindOrders";
import { useDebounce } from "hooks/useDebounce";
import { useErrorModal } from "hooks/useModal";
import usePageTransition from "hooks/usePageTransition";
import { isBrowser, isTablet } from "utils/deviceDetect";

const OptionCreatePresenter = lazy(() =>
  isBrowser
    ? import("pages/OptionCreate/pc/OptionCreatePresenter")
    : isTablet
    ? import("pages/OptionCreate/tb/OptionCreatePresenter")
    : import("pages/OptionCreate/sp/OptionCreatePresenter")
);

export type OptionCreatePresenterProps = {
  step: 1 | 2 | 3;
  handleStep: (step: 1 | 2 | 3) => void;
  onSubmit: () => void;
  navOptionList: () => void;
  navRelatedOrder: (id: string) => void;
  formValues: {
    orders: Order[] | undefined;
    name: string;
    requestServices: string[];
    searchActive: boolean;
    otherRequestContent: string;
    province: string;
    district: string;
    street: string;
    remarks: string;
    order?: { id: string; name: string };
    handleChangeName: (name: string, orderId?: string) => void;
    handleChangeRequestServices: (service: RequestService) => void;
    handleChangeOtherRequestContent: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    handleChangeProvince: (province: string) => void;
    handleChangeDistrict: (e: ChangeEvent<HTMLInputElement>) => void;
    handleChangeStreet: (e: ChangeEvent<HTMLInputElement>) => void;
    handleChangeRemarks: (e: ChangeEvent<HTMLTextAreaElement>) => void;
    handleChangeOrder: (order: { id: string; name: string }) => void;
    handleChangeSearchActive: (active: boolean) => void;
    errors: {
      isErrorName: boolean;
      isErrorRequestServices: boolean;
      isErrorOtherRequestContent: boolean;
      isErrorProvince: boolean;
      isErrorDistrict: boolean;
      isErrorStreet: boolean;
      isErrorRemarks: boolean;
    };
    canSubmit: boolean;
    setOrdersPageIndex: Dispatch<SetStateAction<number>>;
    isLoadingOrders?: boolean;
    hasMoreOrders: boolean;
  };
  ErrorModal: JSX.Element;
};

const OptionCreateContainer = () => {
  const [step, setStep] = useState<1 | 2 | 3>(1);
  const [name, setName] = useState("");
  const [requestServices, setRequestServices] = useState<string[]>([]);
  const [otherRequestContent, setOtherRequestContent] = useState("");
  const [province, setProvince] = useState("");
  const [district, setDistrict] = useState("");
  const [street, setStreet] = useState("");
  const [remarks, setRemarks] = useState("");
  const [order, setOrder] = useState<{ id: string; name: string }>();
  const [searchActive, setSearchActive] = useState(false);
  const [ordersPageIndex, setOrdersPageIndex] = useState(1);
  const [errorModalState, setErrorModalState] = useState<{ httpMethod: HttpMethod; message?: string }>({
    httpMethod: "post",
    message: "",
  });

  const beautifyName = (name: string) => name.trim();
  const { debouncedValue: debouncedSearchKeyword } = useDebounce(name, 500);
  const { createOption } = useCreateOption();
  const navigate = usePageTransition();
  const { orders, isLoading, pagination } = useFindOrders({
    searchKeyword: beautifyName(debouncedSearchKeyword),
    orderStatusFilter: "all",
    orderType: "desc",
    pageNumber: ordersPageIndex,
    pageSize: 25,
    parkingUsageDateFilter: "notExpired",
  });
  const { ErrorModal, showErrorModal } = useErrorModal();

  const handleChangeStep = (step: 1 | 2 | 3) => {
    window.scrollTo(0, 0);
    setStep(step);
  };
  const handleChangeOrder = (order: { id: string; name: string }) => setOrder(order);
  const handleChangeName = (name: string, orderId?: string) => {
    setName(name);
    if (orderId) setOrder({ id: orderId, name });
    if (!orderId) setOrder(undefined);
  };
  const handleChangeOtherRequestContent = (e: ChangeEvent<HTMLTextAreaElement>) =>
    setOtherRequestContent(e.target.value);
  const handleChangeDistrict = (e: ChangeEvent<HTMLInputElement>) => setDistrict(e.target.value);
  const handleChangeProvince = (province: string) => setProvince(province);
  const handleChangeStreet = (e: ChangeEvent<HTMLInputElement>) => setStreet(e.target.value);
  const handleChangeRemarks = (e: ChangeEvent<HTMLTextAreaElement>) => setRemarks(e.target.value);
  const handleChangeRequestServices = (service: RequestService) => {
    // その他の申請が選択されている場合、選択解除時に入力内容をクリア
    if (service === "その他の申請" && requestServices.includes(REQUEST_SERVICES["その他の申請"]))
      setOtherRequestContent("");
    if (requestServices.includes(service)) setRequestServices(requestServices.filter((item) => item !== service));
    else setRequestServices([...requestServices, service]);
  };
  const handleChangeSearchActive = (active: boolean) => setSearchActive(active);

  const hasMoreOrders = pagination.totalPages > ordersPageIndex;
  const isMaxLengthError = (value: string, maxLength = 255) => value.length > maxLength;
  const isErrorName = name.length === 0;
  const isErrorRequestServices = requestServices.length === 0;
  const isErrorOtherRequestContent =
    requestServices.includes(REQUEST_SERVICES["その他の申請"]) &&
    (otherRequestContent.length === 0 || isMaxLengthError(otherRequestContent));
  const isErrorProvince = (district.length !== 0 || street.length !== 0) && province.length === 0;
  const isErrorDistrict = (province.length !== 0 || street.length !== 0) && district.length === 0;
  const isErrorStreet = (province.length !== 0 || district.length !== 0) && street.length === 0;
  const isErrorRemarks = isMaxLengthError(remarks);
  const canSubmit =
    !isErrorName &&
    !isErrorRequestServices &&
    !isErrorOtherRequestContent &&
    !isErrorProvince &&
    !isErrorDistrict &&
    !isErrorStreet &&
    !isErrorRemarks;
  const errors = {
    isErrorName,
    isErrorRequestServices,
    isErrorOtherRequestContent,
    isErrorProvince,
    isErrorDistrict,
    isErrorStreet,
    isErrorRemarks,
  };
  const formValues = {
    orders,
    searchActive,
    name,
    requestServices,
    otherRequestContent,
    province,
    district,
    street,
    remarks,
    order,
    handleChangeName,
    handleChangeRequestServices,
    handleChangeOtherRequestContent,
    handleChangeProvince,
    handleChangeDistrict,
    handleChangeStreet,
    handleChangeRemarks,
    handleChangeOrder,
    handleChangeSearchActive,
    errors,
    canSubmit,
    isLoadingOrders: isLoading,
    setOrdersPageIndex,
    hasMoreOrders,
  };

  const navOptionList = () => navigate("/option");

  const navRelatedOrder = (id: string) => window.open(`/order/${id}`, "_blank");

  const onSubmit = async () => {
    const status = await createOption({
      name,
      requestService: requestServices,
      orderId: order?.id,
      otherRequestContent,
      province,
      district,
      street,
      remarks,
    }).catch((e) => {
      if (e.response.status === 409) {
        setErrorModalState({
          httpMethod: "post",
          message: "既存の検索依頼と重複した名前の申請依頼です",
        });
      } else if (e.response.status === 428) {
        setErrorModalState({
          httpMethod: "post",
          message: "選択した検索依頼の駐車場貸出終了予定日を過ぎているため、申請できません",
        });
      } else {
        setErrorModalState({ httpMethod: "post" });
      }
      showErrorModal();
    });
    if (status === 201) setStep(3);
  };

  return (
    <Suspense fallback={<Loading />}>
      <OptionCreatePresenter
        formValues={formValues}
        step={step}
        handleStep={handleChangeStep}
        onSubmit={onSubmit}
        navOptionList={navOptionList}
        navRelatedOrder={navRelatedOrder}
        ErrorModal={<ErrorModal httpMethod={errorModalState.httpMethod} message={errorModalState.message} />}
      />
    </Suspense>
  );
};

export default OptionCreateContainer;
