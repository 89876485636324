import {
  isBrowser as originalIsBrowser,
  isTablet as originalIsTablet,
  isMobile as originalIsMobile,
} from "react-device-detect";

/**
 * PCとして検知されてしまう幅の広いTBに対してTBのUIを適用する
 * max-widthを1200pxとしたのはレイアウト崩れの原因となる固定値のwidthの最大値が1200pxであったため
 * min-widthを768pxとしたのはReactDeviceDetectでタブレットとして担保されているiPad miniを基準としたため
 */
const isTabletCustom: boolean = window.matchMedia("(min-width: 768px) and (max-width: 1200px)").matches;

export const isBrowser = originalIsBrowser && !isTabletCustom;
export const isTablet = (originalIsBrowser && isTabletCustom) || originalIsTablet;
export const isMobile = originalIsMobile;
