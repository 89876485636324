import { AuthErrorCodes, confirmPasswordReset, verifyPasswordResetCode } from "firebase/auth";
import { lazy, Suspense, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";
import useFirebaseContext from "hooks/useFirebaseContext";
import { useInfoModal } from "hooks/useModal";
import { isBrowser, isTablet } from "utils/deviceDetect";

const PasswordResetChangePresenter = lazy(() =>
  isBrowser
    ? import("pages/PasswordReset/Change/pc/PasswordResetChangePresenter")
    : isTablet
    ? import("pages/PasswordReset/Change/tb/PasswordResetChangePresenter")
    : import("pages/PasswordReset/Change/sp/PasswordResetChangePresenter")
);

const createErrorMessage = (code: string): string => {
  switch (code) {
    case AuthErrorCodes.EXPIRED_OOB_CODE:
      return "パスワードリセットコードの有効期限が切れています。再度申請してください";
    case AuthErrorCodes.INVALID_OOB_CODE:
      return "パスワードリセットコードが無効です。再度申請してください";
    case AuthErrorCodes.USER_DISABLED:
      return "ユーザーが無効になっています。お問い合わせください";
    case AuthErrorCodes.USER_DELETED:
      return "ユーザーが存在しません。お問い合わせください";
    case AuthErrorCodes.WEAK_PASSWORD:
      return "新しいパスワードが脆弱です。再度入力してください";
    default:
      return "予期せぬエラーが発生しました。しばらく時間をおいてお試しください";
  }
};

const PasswordResetChangeContainer = () => {
  const { auth } = useFirebaseContext();
  const navigate = useNavigate();
  const { InfoModal, showInfoModal, hideInfoModal } = useInfoModal();

  const [oobCode, setOobCode] = useState("");

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const disabled = !password.length && !email.length;

  const [errorMessage, setErrorMessage] = useState("");

  const onSend = async () => {
    await confirmPasswordReset(auth, oobCode, password)
      .then(() => {
        showInfoModal();
      })
      .catch((e) => {
        setErrorMessage(createErrorMessage(e.code));
        throw e;
      });
  };

  const handleClose = () => {
    hideInfoModal();
    navigate("/", { replace: true });
  };

  const checkActionCodeAndSetEmail = useCallback(async () => {
    const queryParams = new URLSearchParams(window.location.search);
    const oobCode = queryParams.get("oobCode") || "";

    setOobCode(oobCode);

    // https://firebase.google.com/docs/reference/js/v8/firebase.auth.Auth#verifypasswordresetcode
    const email = await verifyPasswordResetCode(auth, oobCode).catch((e) => {
      setErrorMessage(createErrorMessage(e.code));
      throw e;
    });

    setEmail(email);
  }, [auth]);

  useEffect(() => {
    checkActionCodeAndSetEmail();
  }, [checkActionCodeAndSetEmail]);

  return (
    <Suspense fallback={<Loading />}>
      <PasswordResetChangePresenter
        email={email}
        password={password}
        disabled={disabled}
        errorMessage={errorMessage}
        onSend={onSend}
        setPassword={setPassword}
        InfoModal={InfoModal}
        onClose={handleClose}
      />
    </Suspense>
  );
};

export default PasswordResetChangeContainer;
