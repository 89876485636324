import dayjs from "dayjs";
import { lazy, Suspense, useCallback, useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";

import Loading from "components/Loading";
import { INIT_ACCOUNT, INIT_DEPARTMENT } from "constants/filter";
import useFindProgressSummary from "hooks/api/useFindProgressSummary";
import useGetUsers from "hooks/api/useGetUsers";
import useErrorModalStatusContext from "hooks/useErrorModalStatusContext";
import { useGoogleAnalytics } from "hooks/useGoogleAnalytics";
import useSharedModalContext from "hooks/useSharedModalContext";
import useUserContext from "hooks/useUserContext";
import { OrderStatusFilter } from "pages/OrderList/orderStatusFilter";
import { UsageStatus } from "pages/Usage/usageStatus";
import { findUniqueDepartments } from "utils/accountUtils";
import { isBrowser, isTablet } from "utils/deviceDetect";

const ProgressPresenter = lazy(() =>
  isBrowser
    ? import("pages/Progress/pc/ProgressPresenter")
    : isTablet
    ? import("pages/Progress/tb/ProgressPresenter")
    : import("pages/Progress/sp/ProgressPresenter")
);
const ProgressContainer = () => {
  const navigate = useNavigate();

  const { sendEvent } = useGoogleAnalytics(true);
  const { showModal } = useSharedModalContext();

  const navOrderCreate = useCallback(() => showModal({ modalType: "OrderMethodModal" }), [showModal]);
  const navOptionCreate = useCallback(() => navigate("option/create"), [navigate]);
  const navOrderList = useCallback(() => {
    navigate("order");
    sendEvent({
      action: "検索依頼_ボタン",
      category: "button",
      label: "/order",
    });
  }, [navigate, sendEvent]);
  // TODO そもそも見積待ちに対応するstatusFilterがないので追加する確認する
  const navOrderListUnEstimated = useCallback(() => {
    navigate(`order?filter=${OrderStatusFilter.all}`);
    sendEvent({
      action: "見積待ち_ボタン",
      category: "button",
      label: "/order",
    });
  }, [navigate, sendEvent]);
  const navOrderListUnanswered = useCallback(() => {
    navigate(`order?filter=${OrderStatusFilter.unanswered}`);
    sendEvent({
      action: "見積未返答_ボタン",
      category: "button",
      label: "/order",
    });
  }, [navigate, sendEvent]);
  const navOrderListUncontracted = useCallback(() => {
    navigate(`order?filter=${OrderStatusFilter.uncontracted}`);
    sendEvent({
      action: "契約未締結_ボタン",
      category: "button",
      label: "/order",
    });
  }, [navigate, sendEvent]);
  const navOrderListNotDownloadedBilling = useCallback(() => {
    navigate(`order?filter=${OrderStatusFilter.notDownloadedBilling}`);
    sendEvent({
      action: "請求書未確認_ボタン",
      category: "button",
      label: "/order",
    });
  }, [navigate, sendEvent]);
  const navBeforeUseList = useCallback(() => {
    navigate(`usage?filter=${UsageStatus.beforeUse}`);
    sendEvent({
      action: "駐車場利用前_ボタン",
      category: "button",
      label: "/order",
    });
  }, [navigate, sendEvent]);
  const navUsageList = useCallback(() => {
    navigate(`usage?filter=${UsageStatus.duringUse}`);
    sendEvent({
      action: "駐車場利用中_ボタン",
      category: "button",
      label: "/order",
    });
  }, [navigate, sendEvent]);

  const { accountList } = useGetUsers();
  const departmentList = findUniqueDepartments(accountList);

  const [account, setAccount] = useState<Account>(INIT_ACCOUNT);
  const [department, setDepartment] = useState(INIT_DEPARTMENT);

  const { progressSummary, error } = useFindProgressSummary({
    contact: account.id === INIT_ACCOUNT.id ? undefined : account.id,
    department: department === INIT_DEPARTMENT ? undefined : department,
  });

  const { showErrorModal } = useErrorModalStatusContext();

  const { isMonthlyBillingAccount, user } = useUserContext();
  const nextClosingDate = useMemo(() => {
    if (!isMonthlyBillingAccount) return null;

    const closingDateOfThisMonth = dayjs().set("date", user.billingClosingDay);
    if (dayjs().isAfter(closingDateOfThisMonth)) {
      const endOfNextMonth = dayjs().add(1, "month").endOf("month");
      // 締日が来月の末日より前か
      if (user.billingClosingDay >= Number(endOfNextMonth.format("D"))) {
        // 来月の末日を返す
        return endOfNextMonth.format("YYYY/MM/DD");
      } else {
        // 来月の締日を返す
        return endOfNextMonth.set("date", user.billingClosingDay).format("YYYY/MM/DD");
      }
    }
    return closingDateOfThisMonth.format("YYYY/MM/DD");
  }, [isMonthlyBillingAccount, user.billingClosingDay]);

  useEffect(() => {
    if (error) {
      showErrorModal({
        httpMethod: "get",
      });
    }
  }, [error, showErrorModal]);

  return (
    <Suspense fallback={<Loading />}>
      <ProgressPresenter
        progressSummary={progressSummary}
        onNavOrderCreate={navOrderCreate}
        onNavOrderList={navOrderList}
        onNavOrderListUnEstimated={navOrderListUnEstimated}
        onNavOrderListUnanswered={navOrderListUnanswered}
        onNavOrderListUncontracted={navOrderListUncontracted}
        onNavOrderListNotDownloadedBilling={navOrderListNotDownloadedBilling}
        onNavBeforeUseList={navBeforeUseList}
        onNavUsageList={navUsageList}
        accountList={accountList}
        departmentList={departmentList}
        account={account}
        department={department}
        onSetAccount={setAccount}
        onSetDepartment={setDepartment}
        nextClosingDate={nextClosingDate}
        onNavOptionCreate={navOptionCreate}
      />
    </Suspense>
  );
};

export default ProgressContainer;
