import { faCircleExclamation } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import styled from "styled-components";

import Button from "components/Button";
import Modal from "components/Modal";
import color from "constants/color";
import font from "constants/font";
import { isBrowser, isTablet } from "utils/deviceDetect";

const Body = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
`;
const Error = styled.div`
  width: 400px;
  margin: 40px 0 16px 0;
  border-radius: 8px 8px 0 0;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  max-width: 100vw;
`;
const ExclamationIcon = styled(FontAwesomeIcon)`
  height: 23px;
  width: 23px;
  margin-right: 8px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: ${color.attention};
`;
const Title = styled.span`
  font-size: ${font.size20};
`;
const ActionArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 16px;
  margin: 20px 0;
`;

const NoticeModal = ({
  isVisible,
  onClose,
  title,
  message,
  buttonText,
  onClick,
}: {
  isVisible: boolean;
  onClose: () => void;
  title: string;
  message: string | JSX.Element;
  buttonText?: string;
  onClick?: () => Promise<void> | void;
}) => {
  return (
    <Modal
      width={!isBrowser && !isTablet ? "98%" : "400px"}
      isVisible={isVisible}
      onClose={onClose}
      paddingHorizontal={!isBrowser && !isTablet ? 16 : 0}
    >
      <>
        <Body>
          <Error>
            <ExclamationIcon icon={faCircleExclamation} />
            <Title>{title}</Title>
          </Error>
          {message}
        </Body>
        <ActionArea>
          <Button
            label={buttonText ?? "閉じる"}
            onClick={() => {
              onClose();
              onClick && onClick();
            }}
            type="secondary"
            width="160px"
          />
        </ActionArea>
      </>
    </Modal>
  );
};

export default NoticeModal;
